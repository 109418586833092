import CheckOTPPage from "common/pages/auth/checkOTP/CheckOTP"
// import AuthenticatedRoute from "common/components/AuthenticatedRoute"
// import DevelopmentRoute from "common/components/DevelopmentRoute"
import SignUpFinishPage from "common/pages/auth/finish"
import SignInPage from "common/pages/auth/signIn/SignIn"
import SignUpPage from "common/pages/auth/signUp/SignUp"
import PasswordForgetPage from "common/pages/auth/passwordForget/PasswordForget"
import PasswordChangePage from "common/pages/auth/passwordChange/PasswordChange"
import GraphiQL from "common/pages/dev/graphiql"
import PermissionErrorPage from "common/pages/dev/permissionError"
import RootPage from "./applications/information/pages/Information"
import { createBrowserRouter, Outlet, RouteObject } from "react-router-dom"
import { AdminProtected, Protected } from "utils/auth/protect"

// import { useAuth } from "hooks/useAuth"
import ReviewRoutes from "./applications/review/routes"
import SignUpAccountData from "common/pages/auth/signUpAccountData/SignUpAccountData"
import ContactRoutes from "./applications/contact/routes"
import InformationRoutes from "./applications/information/routes"
import TermPageEn from "common/pages/terms/en/index"
import TermPage from "common/pages/terms"

// const auth = useAuth()
// <AuthenticatedRoute />
export const baseRoutes: RouteObject[] = [
  {
    path: "/",
    element: (
      <Protected>
        <Outlet />
      </Protected>
    ),
    children: [{ index: true, element: <RootPage /> }],
  },
  {
    path: "auth",
    children: [
      { index: true, element: <SignInPage /> }, // it must will be sign out.
      { path: "signin", element: <SignInPage /> },
      { path: "signup", element: <SignUpPage /> },
      { path: "checkotp", element: <CheckOTPPage /> },
      { path: "finish", element: <SignUpFinishPage /> },
      { path: "passwordForget", element: <PasswordForgetPage /> },
      { path: "signupAccountData", element: <SignUpAccountData /> },
      { path: "passwordChange", element: <PasswordChangePage /> },
    ],
  },
  {
    path: "terms_of_service",
    children: [{ index: true, element: <TermPage /> }],
  },
  {
    path: "terms_of_service_en",
    children: [{ index: true, element: <TermPageEn /> }],
  },
  {
    path: "dev",
    element: (
      <Protected>
        <Outlet />
      </Protected>
    ),
    children: [
      { index: true, element: <GraphiQL /> },
      { path: "gql", element: <GraphiQL /> },
      { path: "permission", element: <PermissionErrorPage /> },
    ],
  },
]

export default createBrowserRouter([
  ...baseRoutes,
  // ...develomentOnlyRoutes(),
  ...ReviewRoutes,
  ...ContactRoutes,
  ...InformationRoutes,
  { path: "*", element: <p>Page Not Found</p> },
])
