import "../style.css"

function TermPageEn() {
  return (
    <div id="container">
      <a href="https://kailog.jp/terms_of_service/">
        https://kailog.jp/terms_of_service/
      </a>
      <br />
      <br />
      <b>Terms of Use of Kailog (Portal)</b>
      <br />
      <p>
        These Terms of Use (hereinafter referred to as these “Terms”) set forth
        matters that clients must comply with when using the Kailog (Portal)
        provided by Research and Innovation Co.,Ltd. (the “Company”) and the
        rights and obligations between the Company and clients. If you use such
        services as a client, please be sure to read the full text before
        agreeing to these Terms.
      </p>
      <br />
      <b>Article 1 (Application)</b>
      <ol>
        <li>
          These Terms are intended to set forth the rights and obligations
          between the Company and the Client (as defined in Article 2) in
          connection with the use of the Services (as defined in Article 2) and
          shall apply to any and all relationships between the Client and the
          Company relating to the use of the Services.
        </li>
        <li>
          The rules, various regulations, etc. regarding the Services that the
          Company posts on the Website (as defined in Article 2) from time to
          time shall constitute a part of these Terms.
        </li>
      </ol>
      <br />
      <b>Article 2 (Definitions)</b>
      <p>
        As used herein, the following terms shall have the following meanings.
      </p>
      <ol>
        <li>
          “Services” means the services that allow for a link or single sign-on
          to Client Content (as defined in Item 3) provided by the Company under
          the name “Kailog (Portal)” (if the name or content of the Services is
          changed for any reason, the services after such change are included).
        </li>
        <li>
          “Client” means the individual or corporation that has entered into a
          “Content Use Agreement” (as defined in Item 4) with the Company or
          INTAGE Inc. (“INTAGE”) and enters into this Use Agreement pursuant to
          Article 3.
        </li>
        <li>
          “Content” means individual marketing services such as questionnaires,
          interviews, data analysis that use a purchase database, “Kailog,”
          collected from the shopping app CODE operated by the Company or
          services related to the panel research database provided by INTAGE.
        </li>
        <li>
          “Content Use Agreement” means the use agreement for each type of the
          Content (as defined in the preceding item).
        </li>
        <li>
          “Website” means the website operated by the Company under the domain
          “https://kailog.jp/” on which the Services are available (if the
          domain or content of the Services’ website is changed for any reason,
          the website after such change is included).
        </li>
        <li>
          “Intellectual Property Right” means copyright, patent right, utility
          model right, trademark right, design right, and any other intellectual
          property right (including the right to obtain such rights or to apply
          for registration of such rights).
        </li>
      </ol>
      <br />
      <b>Article 3 (Registration)</b>
      <ol>
        <li>
          Any person who wishes to use the Services (“Registration Applicant”)
          may apply to the Company for registration to use the Services by
          agreeing to comply with these Terms, entering certain information as
          specified by the Company (“Registration Information”) in a use
          application form prescribed by the Company and providing such use
          application form to the Company.
        </li>
        <li>
          {" "}
          If any of the following items applies to a Registration Applicant who
          has applied for registration under the preceding paragraph, the
          Company may refuse the registration:
        </li>
        <ol>
          <li>
            If all or part of the Registration Information provided to the
            Company is false, erroneous, or omitted;
          </li>
          <li>
            If the Registration Applicant is a minor, adult ward, person under
            curatorship, or person under assistance, and has not obtained the
            consent of a legal representative, guardian, curator, or assistant;
          </li>
          <li>
            If the Company determines that the Registration Applicant: (a) is an
            antisocial force (meaning an organized crime group, a member of an
            organized crime group, a quasi-constituent member of an organized
            crime group, an enterprise related to an organized crime group, a
            corporate racketeer (sokaiya), or any other group or individual
            pursuing economic benefits using violence, force, or fraudulent
            means; the same shall apply hereinafter); or (b) has an interaction
            or relationship in some manner with antisocial forces, such as
            cooperating or being involved in the maintenance, operation, or
            management of antisocial forces through the provision of funds or
            other means; or
          </li>
          <li>
            When the Company otherwise deems the registration inappropriate.
          </li>
        </ol>
        <li>
          The Company shall determine whether or not to accept the registration
          of Registration Applicants in accordance with the preceding paragraph
          and other criteria of the Company. If the Company accepts the
          registration, it shall notify the Registration Applicant (“Client”) to
          that effect.
        </li>
        <li>
          If the Company gives notice of acceptance of the registration pursuant
          to the preceding paragraph, an agreement for the use of the Services
          in accordance with various provisions of these Terms (this “Use
          Agreement”) shall be entered into between the Client and the Company
          on the date of such notice or a date separately designated by the
          Company (“Agreement Date”).
        </li>
        <li>
          In order to use the Content, it is necessary to agree to the terms of
          use for each type of the Content separately, and conclude respective
          Content Use Agreements. If the Content Use Agreement excludes the
          application of any part of this Use Agreement or stipulates any matter
          different from this Use Agreement, such Content Use Agreement shall
          prevail. This Use Agreement shall apply to the handling of user IDs
          and passwords issued based on the Services, and the relevant Content
          Use Agreement shall apply to the handling of user IDs and passwords
          issued for each type of the Content.
        </li>
        <li>
          The Client shall, without delay, notify the Company of any changes in
          the Registration Information in accordance with the methods specified
          by the Company and provide any materials requested by the Company.
        </li>
      </ol>
      <br />
      <b>Article 4 (Individual User’s Registration for Use)</b>
      <ol>
        <li>
          The Client shall have each of its officers and employees who use the
          Services (“Individual User”) register for use in accordance with the
          procedures specified by the Company after having them agree to the
          contents of these Terms. At the time of such registration for use, the
          Client shall have each Individual User accurately provide the Company
          with information concerning himself/herself (“Individual User
          Information”).
        </li>
        <li>
          Paragraph 2 of the preceding article shall apply mutatis mutandis to
          the Individual User’s registration for use.
        </li>
        <li>
          In the event of any change in the Individual User Information, the
          Client shall have the relevant Individual User promptly change or
          delete the Individual User Information in accordance with the methods
          specified by the Company.
        </li>
      </ol>
      <br />
      <b>Article 5 (Terms of Use of the Services)</b>
      <ol>
        <li>
          The Client may use the Services during the effective term of this Use
          Agreement in accordance with these Terms and in the manner prescribed
          by the Company.
        </li>
        <li>
          The Client may only allow its officers or employees to use the
          Services, and may not allow any other third party to use the Services.
        </li>
        <li>
          The Client shall have Individual Users comply with these Terms. Any
          and all acts performed by an Individual User on the Services shall be
          deemed to be acts of the Client to which such Individual User belongs.
          The Client shall be liable to the Company for any and all use of the
          Services and compliance with these Terms by Individual Users who
          belong to the Client.
        </li>
      </ol>
      <br />
      <b>Article 6 (Fees and Payment Method)</b>
      <ol>
        <li>The Client may use the Services free of charge.</li>
        <li>
          The use of the Content through the Services may incur usage fees in
          accordance with respective Content Use Agreements.
        </li>
      </ol>
      <br />
      <b>Article 7 (Management of Password and User ID)</b>
      <ol>
        <li>
          The Client shall manage and store the password and user ID on its own
          responsibility.
        </li>
        <li>
          The Client shall not share, lend, assign, change the name of, sell, or
          purchase the password and user ID.
        </li>
        <li>
          The Client shall be, and the Company shall not be, liable for any
          damage caused by inadequate management, misuse, use by a third party,
          etc. of the password or user ID.
        </li>
        <li>
          The Company shall consider the use of the Content via the Services as
          use by the Client, even if such use is by a third party other than the
          Client.
        </li>
        <li>
          In the event that the Client finds that the password or user ID has
          been stolen or used by a third party, the Client shall immediately
          notify the Company to that effect and follow the Company’s
          instructions.
        </li>
      </ol>
      <br />
      <b>Article 8 (Handling of User Information)</b>
      <p>
        The Company shall handle personal information of Clients and Individual
        Users registered by Clients prior to the use of the Services and
        Content, as well as history of use of the Services and Content by
        Clients and Individual Users (“User Information”) as follows.
      </p>
      <ol>
        <li>
          Information to be collected
          <br />
          * Name
          <br />
          * Email address
          <br />
          * Company name
          <br />
          * Department name
          <br />
          * History of use of the Services and Content
          <br />
          * Details of use of the Services and Content
          <br />
        </li>
        <li>
          Purpose of use of User Information
          <br />
          * To operate and manage the Services and Content
          <br />
          * To notify passwords for use of the Services and Content
          <br />
          * To ensure traceability in the event of system trouble related to the
          Services and Content, to confirm the details of such trouble, etc.
          <br />
          * To improve the Services and Content
          <br />
          * To implement various measures for promotion of use of the Services
          and Content
          <br />
        </li>
        <li>
          Outsourcing the handling of User Information
          <br />
          The Company outsources all or part of the handling of User
          Information. In such cases, the Company shall exercise necessary and
          sufficient supervision over such contractors to ensure the safe
          management of User Information.
        </li>
        <li>
          4. Other
          <br />
          In addition to the preceding items, the handling of User Information
          shall be subject to the Company’s Privacy Policy
          (https://r-n-i.jp/privacy/).
        </li>
      </ol>
      <br />
      <b>Article 9 (Prohibited Acts)</b>
      <ol>
        1. In using the Services, the Client shall not commit:
        <ol>
          <li>
            Any act of infringing on the Company’s or a third party’s
            Intellectual Property Rights, portrait rights, right to privacy,
            reputation, or other rights or interests (including any act that
            directly or indirectly causes such infringement);
          </li>
          <li>Any criminal act or act against public order and morals;</li>
          <li>
            Any act of transmitting information containing computer viruses or
            other harmful computer programs;
          </li>
          <li>
            Any act that may interfere with the operation of the Services by the
            Company; or
          </li>
          <li>Any other acts that the Company deems inappropriate.</li>
        </ol>
      </ol>
      <br />
      <b>Article 10 (Suspension of the Services, etc.)</b>
      <ol>
        <li>
          The Company may suspend or discontinue use of the Services, in whole
          or in part, without prior notice to the Client, when:
        </li>
        <ol>
          <li>
            The Company conducts a regular or emergency check or maintenance of
            the computer systems and equipment pertaining to the Services;
          </li>
          <li>
            Any computer, communication line, or the like stops because of an
            accident;
          </li>
          <li>
            The Company becomes unable to operate the Services because of
            natural disasters, war, subversive activities such as terrorist
            activities, or other emergency reasons;
          </li>
          <li>
            The Company becomes unable to operate the Services because of a
            disaster, power failure, or other circumstances beyond the Company’s
            control or not attributable to the Company;
          </li>
          <li>
            The data analysis platform used by the Company becomes unusable; or
          </li>
          <li>
            The Company otherwise finds it necessary to suspend or discontinue
            the provision of the Services.
          </li>
        </ol>
        <li>
          Emergency maintenance may occur unexpectedly in the Services. This
          results, without prior notification to Clients, in periods of time
          when they are unable to view the data.
        </li>
        <li>
          The Company may add new services to the Services, change the Services,
          or terminate the Services in whole or in part at the Company’s
          convenience.
        </li>
        <li>
          The Company shall assume no liability for any damage caused to the
          Client through measures taken by the Company pursuant to this article.
        </li>
      </ol>
      <br />
      <b>Article 11 (Burden of Equipment, etc.)</b>
      <ol>
        <li>
          The Client shall, at its own expense and on its own responsibility,
          prepare and maintain computers, software, and other equipment,
          communication lines and other communication environments, etc.,
          necessary to receive the Services. The Company makes no warranty as to
          the operation warranty of the Services or the suitability of the
          Services for the equipment used.
        </li>
        <li>
          The Client shall, at its own expense and on its own responsibility,
          take security measures to prevent computer viruses, unauthorized
          access, information leaks, etc., in accordance with its own
          environment for using the Services.
        </li>
        <li>
          If the Client installs software, etc., on the Client’s computer, etc.,
          by way of downloading from the Website or otherwise in commencing the
          use of the Services or during the use of the Services, the Client
          shall pay adequate care to prevent any loss or alteration of
          information held by the Client, or any failure of or damage, etc., to
          equipment. The Company shall not be liable for any such damage
          incurred by the Client.
        </li>
      </ol>
      <br />
      <b>Article 12 (Belonging of Rights)</b>
      <ol>
        <li>
          All ownership rights and Intellectual Property Rights related to the
          Website and the Services belong to the Company. The granting of a
          license to use the Services based on registration as stipulated in
          these Terms shall not mean the granting of a license to use the
          Company or the Company’s Intellectual Property Rights related to the
          Website or the Services, except as expressly stated in these Terms.
        </li>
        <li>
          The Client shall not perform any act of reproducing, altering,
          reverse-engineering, decompiling or disassembling software, or the
          like to provide the Services or otherwise infringing on the Company’s
          Intellectual Property Rights pertaining to the Services.
        </li>
      </ol>
      <br />
      <b>
        Article 13 (Suspension of Use of the Services; Termination of Agreement)
      </b>
      <ol>
        <li>
          If the Client (including Individual Users who belong to the Client)
          falls under any of the following items, the Company may temporarily
          suspend the use of the Services without prior notice or demand:
        </li>
        <ol>
          <li>If the Client breaches any of the provisions of these Terms;</li>
          <li>
            If it turns out that a false statement is included in the
            Registration Information or Individual User Information;
          </li>
          <li>
            If the Client ceases to make payments or becomes insolvent, or files
            a petition for commencement of bankruptcy proceedings, civil
            rehabilitation proceedings, corporate reorganization proceedings,
            special liquidation, or any other similar proceedings;
          </li>
          <li>
            If the Client fails to respond to contact from the Company for
            thirty (30) days or more;
          </li>
          <li>
            If the Client falls under any of the items of Article 3, paragraph 2
            or Article 9, paragraph 1; or
          </li>
          <li>
            If the Company otherwise considers it inappropriate for the Client
            to continue its registration.
          </li>
        </ol>
        <li>
          In the event that the Client falls under any of the items of the
          preceding paragraph, the Company may cancel Individual User’s
          registration for use or terminate this Use Agreement by giving at
          least seven (7) days’ prior notice to the Client in the manner
          prescribed by the Company.
        </li>
        <li>
          The Company shall assume no liability for any damage caused to the
          Client through an act performed by the Company pursuant to this
          article.
        </li>
      </ol>
      <br />
      <b>Article 14 (Disclaimer and Exemption)</b>
      <ol>
        <li>
          The Company does not give any warranty concerning the Services. The
          Services are provided as is, and the Company gives no warranty
          concerning the Services, including but not limited to the suitability
          for the Client’s specific purpose, its expected functionality,
          commercial usefulness, accuracy, completeness, continuous usability,
          or non-occurrence of a defect.
        </li>
        <li>
          Even if the Client directly or indirectly receives from the Company
          any information about the Services, the Website, or any other matters,
          the Company shall not give any warranty to the Client beyond the
          provisions prescribed in these Terms.
        </li>
        <li>
          The Client shall, at its own expense and on its own responsibility,
          examine whether or not using the Services violates laws and
          regulations applicable to the Client or the internal rules of industry
          groups. The Company does not give any warranty that the Client’s use
          of the Services conforms to laws and regulations applicable to the
          Client or the internal rules of industry groups.
        </li>
        <li>
          The Client shall handle or settle transactions, communication,
          disputes, etc., between it and a third party in connection with the
          Services on its own responsibility, and the Company shall assume no
          responsibility for such matters.
        </li>
        <li>
          The Company shall assume no liability to compensate for
          discontinuation, suspension, end, unavailability or change of the
          Company’s provision of the Services, cancellation of the Client’s
          registration, loss of data or breakdown of or damage to equipment
          through use of the Services, or other damage suffered by the Client in
          connection with the Services.
        </li>
        <li>
          Even if a link from the Website to other websites, or a link from
          other websites to the Website has been provided, the Company shall not
          be responsible for any website other than the Website or information
          obtained therefrom for any reason whatsoever.
        </li>
        <li>
          The Company shall not be liable for any damages incurred by the Client
          in relation to the Services except in the case of willful misconduct
          or gross negligence on the part of the Company. Even if the Company is
          liable to the Client for damages due to the application of laws and
          regulations or for other reasons, the scope of damages to be
          compensated for shall be limited to direct and ordinary damages
          actually incurred by the Individual User, and shall not include
          incidental damages, indirect damages, or special damages including
          lost profits.
        </li>
      </ol>
      <br />
      <b>Article 15 (Liability for Damage, etc.)</b>
      <p>
        The Client shall compensate the Company for any damage caused to the
        Company by the Client’s violation of these Terms or in connection with
        the Client’s use of the Services.
      </p>
      <br />
      <b>Article 16 (Confidentiality)</b>
      <ol>
        <li>
          The Client and the Company shall strictly and properly manage the
          other party’s confidential information (including know-how concerning
          the Services, information concerning the Company’s systems, and any
          and all confidential technical and business information) obtained in
          connection with the provision of the Services. Neither the Client nor
          the Company shall, without the prior written consent of the other
          party, disclose, provide, or leak such confidential information to any
          third party (including the Company’s affiliates and contractors). In
          addition, confidential information shall not be used for any purpose
          other than the exercise of rights or the performance of obligations
          under these Terms and this Use Agreement.
        </li>
        <li>
          {" "}
          The following shall not be deemed to be confidential information:
        </li>
        <ol>
          <li>
            Information that was already in the possession of the receiving
            party at the time of disclosure;
          </li>
          <li>
            Information that was already in the public domain at the time of
            disclosure or thereafter becomes part of the public domain through
            no fault of the receiving party;
          </li>
          <li>
            Information that is lawfully obtained from a third party after
            disclosure; and
          </li>
          <li>
            Information independently developed or created without reference to
            or use of the disclosed confidential information.
          </li>
        </ol>
        <li>
          Notwithstanding the preceding paragraph, the Client and the Company
          may, if ordered by a court or administrative agency to disclose
          confidential information or required to disclose confidential
          information under laws and regulations, disclose confidential
          information to the extent of such order or requirement. Provided,
          however, that if either party receives such order, demand, or request,
          it shall promptly notify the other party to that effect.
        </li>
        <li>
          Notwithstanding paragraph 1, the Company may disclose confidential
          information of the Client to INTAGE HOLDINGS Inc., INTAGE Inc. and
          INTAGE ASSOCIATES Inc. (collectively the “Receiving Parties”) only to
          the extent necessary for the purpose of performing operations such as
          agreement and business partner management, accounting or tax
          processing or verification, and sending greeting cards. Provided,
          however, that the Company shall impose on any Receiving Party the same
          obligations as those imposed on itself under this Use Agreement, and
          shall be liable to the Client for any breach of such obligations
          committed by the Receiving Party.
        </li>
        <li>
          Either the Client or the Company shall, when instructed by the other
          party or this Use Agreement is terminated, promptly return or dispose
          of confidential information in accordance with the instructions of the
          other party and shall not use it thereafter.
        </li>
      </ol>
      <br />
      <b>Article 17 (Effective Term)</b>
      <ol>
        <li>
          This Use Agreement shall become effective on the Agreement Date set
          forth in Article 3, paragraph 4 and shall remain in effect between the
          Company and the Client until the earlier of the date of cancellation
          of this Use Agreement or the date of termination of provision of the
          Services.
        </li>
        <li>
          The provisions of Article 16 shall survive the termination of this Use
          Agreement for three (3) years.
        </li>
      </ol>
      <br />
      <b>Article 18 (Changes to These Terms, etc.)</b>
      <ol>
        <li>The Company may freely change the contents of the Services.</li>
        <li>
          The Company may change these Terms (including rules, regulations,
          etc., regarding the Services posted on the Website; the same shall
          apply hereinafter in this paragraph). When changing these Terms, the
          Company shall notify the Client in advance by the method prescribed in
          Article 19, specifying the details and the effective date of such
          change.
        </li>
      </ol>
      <br />
      <b>Article 19 (Communication/Notice)</b>
      <p>
        Any inquiries with respect to the Services and any other communications
        or notices from the Client to the Company, as well as any notice of
        amendments to these Terms and any other communications or notices from
        the Company to the Client shall be made in the manner specified by the
        Company.
      </p>
      <br />
      <b>Article 20 (Assignment of These Terms, etc.)</b>
      <ol>
        <li>
          The Client may not assign, transfer, provide as security, or otherwise
          dispose of the status under this Use Agreement or any rights or
          obligations under these Terms to any third party without the prior
          written consent of the Company.
        </li>
        <li>
          When the Company transfers the business pertaining to the Services to
          another company, the Company may, upon the business transfer, transfer
          the status under this Use Agreement and the rights and obligations
          under these Terms, as well as the Client’s Registration Information
          and other customer information to the transferee of such business. The
          Client shall be considered to have agreed on such transfer in advance
          in this paragraph. Moreover, the business transfer specified in this
          paragraph shall include not only ordinary business transfer but also a
          company split and all other cases where business is transferred.
        </li>
      </ol>
      <br />
      <b>Article 21 (Severability)</b>
      <p>
        Even if any clause of these Terms or any part thereof is held invalid or
        unenforceable pursuant to the Consumer Contract Act or other laws or
        regulations, the remaining provisions of these Terms and the remaining
        part of the provisions part of which has been held invalid or
        unenforceable shall continue to remain in full force, and the Company
        and the Client shall modify the clause or part held invalid or
        unenforceable to a necessary extent to make that clause or part legal
        and enforceable to endeavor to secure the purpose of the invalid or
        unenforceable clause or part and an effect legally and economically
        equivalent to that of the clause or part.
      </p>
      <br />
      <b>Article 22 (Governing Law and Jurisdiction)</b>
      <p>
        These Terms shall be governed by the laws of Japan. The Tokyo District
        Court shall be the court of first instance having exclusive jurisdiction
        over any and all disputes arising out of or in connection with these
        Terms.
      </p>
      <br />
      <b>Article 23 (Resolution by Consultation)</b>
      <p>
        The Company and the Client shall consult with each other in accordance
        with the principle of good faith to promptly resolve any matter not
        stipulated in these Terms or any doubt arising with respect to the
        interpretation of these Terms.
      </p>
      [Established on May 10, 2024]
    </div>
  )
}

export default TermPageEn
